@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/muli-v11-latin_latin-ext-regular.eot');
  /* IE9 Compat Modes */
  src: local('Muli Regular'), local('Muli-Regular'), url('../fonts//muli-v11-latin_latin-ext-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */ url('../fonts//muli-v11-latin_latin-ext-regular.woff2') format('woff2'), /* Super Modern Browsers */ url('../fonts//muli-v11-latin_latin-ext-regular.woff') format('woff'), /* Modern Browsers */ url('../fonts//muli-v11-latin_latin-ext-regular.ttf') format('truetype'), /* Safari, Android, iOS */ url('../fonts//muli-v11-latin_latin-ext-regular.svg#Muli') format('svg');
  /* Legacy iOS */
}
html,
body {
  font-size: 20px;
  font-family: "Muli", Arial, sans-serif;
}
a {
  cursor: pointer;
  color: #6D54FF;
}
.page-header {
  border: none;
}
.page-header .title {
  color: #3F3D35;
  font-size: 1.9rem;
}
@media screen and (min-width: 375px) {
  .page-header .title {
    font-size: calc(1.9rem + 32 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .page-header .title {
    font-size: 3.5rem;
  }
}
.page-header .subtitle {
  color: #6D54FF;
  font-size: 1.1rem;
}
@media screen and (min-width: 375px) {
  .page-header .subtitle {
    font-size: calc(1.1rem + 3 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .page-header .subtitle {
    font-size: 1.25rem;
  }
}
.page-header .description {
  color: #B0B39D;
  font-size: 1.1rem;
}
@media screen and (min-width: 375px) {
  .page-header .description {
    font-size: calc(1.1rem + 3 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .page-header .description {
    font-size: 1.25rem;
  }
}
.page-header .text-questionnaire-title {
  margin: 0;
}
.page-header .text-questionnaire-section {
  margin: 0.5rem 0 0 0;
}
.text-default {
  color: #3F3D35;
}
.text-primary {
  color: #6D54FF;
}
.text-secondary {
  color: #B0B39D;
}
.text-questionnaire-title {
  font-size: 1rem;
}
@media screen and (min-width: 375px) {
  .text-questionnaire-title {
    font-size: calc(1rem + 15 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .text-questionnaire-title {
    font-size: 1.75rem;
  }
}
.text-questionnaire-section {
  font-size: 0.9rem;
}
@media screen and (min-width: 375px) {
  .text-questionnaire-section {
    font-size: calc(0.9rem + 7 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .text-questionnaire-section {
    font-size: 1.25rem;
  }
}
.text-question {
  font-size: 1.1rem;
  font-weight: bold;
  margin-top: 0.75rem;
  margin-bottom: 0.4rem;
}
@media screen and (min-width: 375px) {
  .text-question {
    font-size: calc(1.1rem + 13 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .text-question {
    font-size: 1.75rem;
  }
}
@media screen and (min-width: 375px) {
  .text-question {
    margin-top: calc(0.75rem + 65 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .text-question {
    margin-top: 4rem;
  }
}
@media screen and (min-width: 375px) {
  .text-question {
    margin-bottom: calc(0.4rem + 17 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .text-question {
    margin-bottom: 1.25rem;
  }
}
.text-question-sub {
  font-size: 1rem;
  font-weight: bold;
  margin-top: 0.75rem;
  margin-bottom: 0.4rem;
}
@media screen and (min-width: 375px) {
  .text-question-sub {
    font-size: calc(1rem + 10 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .text-question-sub {
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 375px) {
  .text-question-sub {
    margin-top: calc(0.75rem + 65 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .text-question-sub {
    margin-top: 4rem;
  }
}
@media screen and (min-width: 375px) {
  .text-question-sub {
    margin-bottom: calc(0.4rem + 17 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .text-question-sub {
    margin-bottom: 1.25rem;
  }
}
.text-question-definition {
  font-size: 1rem;
  margin-top: 0.75rem;
  margin-bottom: 0.4rem;
}
@media screen and (min-width: 375px) {
  .text-question-definition {
    font-size: calc(1rem + 10 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .text-question-definition {
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 375px) {
  .text-question-definition {
    margin-top: calc(0.75rem + 25 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .text-question-definition {
    margin-top: 2rem;
  }
}
@media screen and (min-width: 375px) {
  .text-question-definition {
    margin-bottom: calc(0.4rem + 17 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .text-question-definition {
    margin-bottom: 1.25rem;
  }
}
.text-question-answer {
  font-size: 1rem;
}
@media screen and (min-width: 375px) {
  .text-question-answer {
    font-size: calc(1rem + 5 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .text-question-answer {
    font-size: 1.25rem;
  }
}
.text-label {
  font-size: 0.9rem;
  font-weight: bold;
}
@media screen and (min-width: 375px) {
  .text-label {
    font-size: calc(0.9rem + 4 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .text-label {
    font-size: 1.1rem;
  }
}
.text-tooltip {
  font-size: 0.9rem;
  margin-bottom: 1.05rem;
}
@media screen and (min-width: 375px) {
  .text-tooltip {
    font-size: calc(0.9rem + 4 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .text-tooltip {
    font-size: 1.1rem;
  }
}
@media screen and (min-width: 375px) {
  .text-tooltip {
    margin-bottom: calc(1.05rem + 4 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .text-tooltip {
    margin-bottom: 1.25rem;
  }
}
.text-dashboard-welcome {
  font-size: 1.9rem;
  font-weight: bold;
}
@media screen and (min-width: 375px) {
  .text-dashboard-welcome {
    font-size: calc(1.9rem + 32 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .text-dashboard-welcome {
    font-size: 3.5rem;
  }
}
.text-dashboard-introduction {
  font-size: 1.1rem;
}
@media screen and (min-width: 375px) {
  .text-dashboard-introduction {
    font-size: calc(1.1rem + 3 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .text-dashboard-introduction {
    font-size: 1.25rem;
  }
}
.text-dashboard-subtitle {
  font-size: 1.1rem;
}
@media screen and (min-width: 375px) {
  .text-dashboard-subtitle {
    font-size: calc(1.1rem + 3 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .text-dashboard-subtitle {
    font-size: 1.25rem;
  }
}
.text-dashboard-status-label {
  font-size: 0.9rem;
}
@media screen and (min-width: 375px) {
  .text-dashboard-status-label {
    font-size: calc(0.9rem + 2 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .text-dashboard-status-label {
    font-size: 1rem;
  }
}
.text-dashboard-questionnaire-title {
  font-size: 1rem;
}
@media screen and (min-width: 375px) {
  .text-dashboard-questionnaire-title {
    font-size: calc(1rem + 10 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .text-dashboard-questionnaire-title {
    font-size: 1.5rem;
  }
}
.container {
  width: auto !important;
  margin-left: 0.8rem;
  margin-right: 0.8rem;
  padding-left: 0;
  padding-right: 0;
}
@media screen and (min-width: 375px) {
  .container {
    margin-left: calc(0.8rem + 159 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .container {
    margin-left: 8.75rem;
  }
}
@media screen and (min-width: 375px) {
  .container {
    margin-right: calc(0.8rem + 159 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .container {
    margin-right: 8.75rem;
  }
}
.btn {
  border-radius: 10px;
  font-size: 0.9rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 0.55rem;
  padding-bottom: 0.55rem;
  border: 2px solid;
  align-items: center;
  white-space: nowrap;
  text-align: center;
  border-color: transparent;
}
@media screen and (min-width: 375px) {
  .btn {
    font-size: calc(0.9rem + 4 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .btn {
    font-size: 1.1rem;
  }
}
@media screen and (min-width: 375px) {
  .btn {
    padding-left: calc(1.5rem + 5 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .btn {
    padding-left: 1.75rem;
  }
}
@media screen and (min-width: 375px) {
  .btn {
    padding-right: calc(1.5rem + 5 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .btn {
    padding-right: 1.75rem;
  }
}
@media screen and (min-width: 375px) {
  .btn {
    padding-top: calc(0.55rem + 5 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .btn {
    padding-top: 0.8rem;
  }
}
@media screen and (min-width: 375px) {
  .btn {
    padding-bottom: calc(0.55rem + 5 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .btn {
    padding-bottom: 0.8rem;
  }
}
.btn:active,
.btn.active,
.btn:focus,
.btn:active:focus .btn.active:focus {
  box-shadow: none !important;
  outline: none !important;
}
.btn .icon {
  font-size: 0.8rem;
}
.btn .icon:first-child {
  margin-right: 0;
}
.btn .icon:last-child {
  margin-left: 0;
}
.btn .icon-right,
.btn .icon-left {
  position: relative;
  top: -0.1em;
}
.btn .icon-right {
  left: 0.3em;
}
.btn .icon-left {
  left: -0.3em;
}
.btn .icon-down {
  position: relative;
  top: 0.1em;
}
.btn-default {
  background: #3F3D35;
  color: #fff;
  border-color: #3F3D35;
}
.btn-default:active,
.btn-default:focus,
.btn-default:hover,
.btn-default:active:focus,
.btn-default:active:hover {
  background: #fff;
  border-color: #3F3D35;
  color: #3F3D35;
}
.btn-default.btn-flat,
.btn-default.btn-link {
  background: transparent;
  border-color: transparent;
  color: #3F3D35;
}
.btn-default.btn-flat:active,
.btn-default.btn-link:active,
.btn-default.btn-flat:focus,
.btn-default.btn-link:focus,
.btn-default.btn-flat:active:focus,
.btn-default.btn-link:active:focus {
  border-color: transparent;
}
.btn-primary {
  background: #6D54FF;
  color: #fff;
  border-color: #6D54FF;
}
.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover,
.btn-primary:active:focus,
.btn-primary:active:hover {
  background: #fff;
  border-color: #6D54FF;
  color: #6D54FF;
}
.btn-primary.btn-flat,
.btn-primary.btn-link {
  background: transparent;
  border-color: transparent;
  color: #6D54FF;
}
.btn-primary.btn-flat:active,
.btn-primary.btn-link:active,
.btn-primary.btn-flat:focus,
.btn-primary.btn-link:focus,
.btn-primary.btn-flat:active:focus,
.btn-primary.btn-link:active:focus {
  border-color: transparent;
}
.btn-secondary {
  background: #B0B39D;
  color: #fff;
  border-color: #B0B39D;
}
.btn-secondary:active,
.btn-secondary:focus,
.btn-secondary:hover,
.btn-secondary:active:focus,
.btn-secondary:active:hover {
  background: #fff;
  border-color: #B0B39D;
  color: #B0B39D;
}
.btn-secondary.btn-flat,
.btn-secondary.btn-link {
  background: transparent;
  border-color: transparent;
  color: #B0B39D;
}
.btn-secondary.btn-flat:active,
.btn-secondary.btn-link:active,
.btn-secondary.btn-flat:focus,
.btn-secondary.btn-link:focus,
.btn-secondary.btn-flat:active:focus,
.btn-secondary.btn-link:active:focus {
  border-color: transparent;
}
.icon {
  line-height: inherit;
}
.icon-down:before {
  content: "\e900";
}
.icon-left:before {
  content: "\e901";
}
.icon-right:before {
  content: "\e902";
}
.icon-language:before {
  content: "\e903";
}
.icon-untick:before {
  content: "\e904";
}
.icon-tick:before {
  content: "\e905";
}
.icon-info:before {
  content: "\e906";
}
.icon-info-hover:before {
  content: "\e916";
}
.icon-link:before {
  content: "\e907";
}
.icon-link-hover:before {
  content: "\e917";
}
.icon-progress-doing:before {
  content: "\e90e";
}
.icon-progress-todo:before {
  content: "\e910";
}
.icon-delete:before {
  content: "\e90d";
}
.form-group {
  margin-bottom: 0.75rem;
}
@media screen and (min-width: 375px) {
  .form-group {
    margin-bottom: calc(0.75rem + 5 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .form-group {
    margin-bottom: 1rem;
  }
}
.control-label {
  font-size: 0.9rem;
  margin-bottom: 2px;
  color: #3F3D35;
  white-space: nowrap;
}
@media screen and (min-width: 375px) {
  .control-label {
    font-size: calc(0.9rem + 4 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .control-label {
    font-size: 1.1rem;
  }
}
.form-control {
  height: auto;
  font-size: 1rem;
  padding: 0 20px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  box-shadow: none;
  border: 2px solid #B0B39D;
  border-radius: 10px;
}
@media screen and (min-width: 375px) {
  .form-control {
    font-size: calc(1rem + 5 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .form-control {
    font-size: 1.25rem;
  }
}
@media screen and (min-width: 375px) {
  .form-control {
    padding-top: calc(0.5rem + 5 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .form-control {
    padding-top: 0.75rem;
  }
}
@media screen and (min-width: 375px) {
  .form-control {
    padding-bottom: calc(0.5rem + 5 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .form-control {
    padding-bottom: 0.75rem;
  }
}
.form-control:disabled,
.form-control::placeholder {
  color: #B0B39D;
}
.form-control.placeholder {
  color: #B0B39D;
}
textarea.form-control {
  resize: vertical;
  min-height: 2.25rem;
}
@media screen and (min-width: 375px) {
  textarea.form-control {
    min-height: calc(2.25rem + 15 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  textarea.form-control {
    min-height: 3rem;
  }
}
.form-group:not([class*="has"]) .form-control:hover:not(:disabled),
.form-group:not([class*="has"]) .form-control:focus {
  border-color: #6D54FF !important;
  box-shadow: none !important;
}
.form-group[class*="has"] .input-group-addon {
  background-color: transparent;
}
.input-group {
  width: 100%;
}
.input-group .form-control {
  border-radius: 10px !important;
}
.input-group-addon {
  background: transparent;
  border: none;
  width: auto;
}
.control-index {
  color: #3F3D35;
  font-size: 0.9rem;
  padding-left: 0;
  padding-right: 0.5rem;
  padding-top: 0.65rem;
  font-weight: bold;
  vertical-align: top;
  width: 1%;
}
@media screen and (min-width: 375px) {
  .control-index {
    font-size: calc(0.9rem + 4 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .control-index {
    font-size: 1.1rem;
  }
}
@media screen and (min-width: 375px) {
  .control-index {
    padding-right: calc(0.5rem + 7 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .control-index {
    padding-right: 0.85rem;
  }
}
@media screen and (min-width: 375px) {
  .control-index {
    padding-top: calc(0.65rem + 5 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .control-index {
    padding-top: 0.9rem;
  }
}
.control-tooltip {
  text-align: center;
  font-size: 1.5rem;
  padding-bottom: 0.35rem;
  line-height: 0;
  width: 1%;
}
@media screen and (min-width: 375px) {
  .control-tooltip {
    font-size: calc(1.5rem + 10 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .control-tooltip {
    font-size: 2rem;
  }
}
@media screen and (min-width: 375px) {
  .control-tooltip {
    padding-bottom: calc(0.35rem + 3 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .control-tooltip {
    padding-bottom: 0.5rem;
  }
}
.control-tooltip .btn-icon {
  line-height: 1;
}
input ~ .control-tooltip {
  vertical-align: middle;
}
textarea ~ .control-tooltip {
  vertical-align: bottom;
}
.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline,
.has-error.radio label,
.has-error.checkbox label,
.has-error.radio-inline label,
.has-error.checkbox-inline label {
  color: #FE5F55;
}
.has-error .form-control {
  border-color: #FE5F55;
}
.has-error .form-control:focus {
  border-color: #fe2f22;
}
.has-error .input-group-addon {
  color: #FE5F55;
  border-color: #FE5F55;
  background-color: #FE5F55;
}
.has-error .form-control-feedback {
  color: #FE5F55;
}
.has-error .form-control {
  color: #FE5F55;
}
.has-error .form-control:focus {
  box-shadow: none;
}
.checkbox,
.radio {
  margin-bottom: 1.5rem;
}
@media screen and (min-width: 375px) {
  .checkbox,
  .radio {
    margin-bottom: calc(1.5rem + 0 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .checkbox,
  .radio {
    margin-bottom: 1.5rem;
  }
}
.checkbox input[type="radio"],
.radio input[type="radio"],
.checkbox input[type="checkbox"],
.radio input[type="checkbox"] {
  opacity: 0;
}
.checkbox label,
.radio label {
  margin: 0;
  padding: 0;
}
.checkbox input + .label-text,
.radio input + .label-text {
  font-size: 1rem;
  height: 1.2rem;
}
@media screen and (min-width: 375px) {
  .checkbox input + .label-text,
  .radio input + .label-text {
    font-size: calc(1rem + 5 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .checkbox input + .label-text,
  .radio input + .label-text {
    font-size: 1.25rem;
  }
}
@media screen and (min-width: 375px) {
  .checkbox input + .label-text,
  .radio input + .label-text {
    height: calc(1.2rem + 16 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .checkbox input + .label-text,
  .radio input + .label-text {
    height: 2rem;
  }
}
.checkbox input + .label-text::before,
.radio input + .label-text::before {
  color: #6D54FF;
  font-family: 'MediPrepare-Icons';
  left: 0;
  font-size: 1.2rem;
  margin-right: 0.75rem;
}
@media screen and (min-width: 375px) {
  .checkbox input + .label-text::before,
  .radio input + .label-text::before {
    font-size: calc(1.2rem + 16 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .checkbox input + .label-text::before,
  .radio input + .label-text::before {
    font-size: 2rem;
  }
}
@media screen and (min-width: 375px) {
  .checkbox input + .label-text::before,
  .radio input + .label-text::before {
    margin-right: calc(0.75rem + 7 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .checkbox input + .label-text::before,
  .radio input + .label-text::before {
    margin-right: 1.1rem;
  }
}
.checkbox input:checked + .label-text::before,
.radio input:checked + .label-text::before {
  font-family: 'MediPrepare-Icons';
}
.checkbox input:disabled + .label-text,
.radio input:disabled + .label-text {
  color: #B0B39D;
}
.checkbox input:disabled + .label-text::before,
.radio input:disabled + .label-text::before {
  color: #B0B39D;
}
.checkbox .form-group,
.radio .form-group {
  display: inline-block;
}
.checkbox input + .label-text::before {
  content: '\e904';
}
.checkbox:hover input:not(:checked):not(:disabled) + .label-text::before {
  content: '\e918';
}
.checkbox input:checked + .label-text::before {
  content: '\e905';
}
.checkbox input:disabled + .label-text {
  color: #B0B39D;
}
.checkbox input:disabled + .label-text::before {
  color: #B0B39D;
}
.radio {
  display: table;
}
.radio input + .label-text::before {
  content: '\e90c';
}
.radio:hover input:not(:checked):not(:disabled) + .label-text::before {
  content: '\e90a';
}
.radio input:checked + .label-text::before {
  content: '\e90b';
}
.radio label {
  display: table-cell;
  vertical-align: middle;
}
.radio .form-group {
  display: table-cell;
  margin: 0;
}
.radio input:disabled + .label-text {
  color: #B0B39D;
}
.radio input:disabled + .label-text::before {
  color: #B0B39D;
}
.btn-group.switch {
  width: 100%;
}
.btn-group.switch .btn {
  color: #B0B39D;
  border-color: #B0B39D;
}
.btn-group.switch .btn:hover {
  color: #6D54FF;
  border-color: #6D54FF;
}
.btn-group.switch .btn:not(:first-child) {
  margin-left: -2px;
}
.btn-group.switch .btn.active {
  background: #6D54FF;
  color: #fff;
  border-color: #6D54FF;
  z-index: 3;
}
.btn-group.switch .btn.active:active,
.btn-group.switch .btn.active:focus,
.btn-group.switch .btn.active:hover,
.btn-group.switch .btn.active:active:focus,
.btn-group.switch .btn.active:active:hover {
  background: #6D54FF;
  border-color: #6D54FF;
  color: #fff;
}
.panel {
  border: none;
  box-shadow: none;
}
.panel-heading {
  border: 1px solid;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  border-color: #6D54FF;
  background-color: #6D54FF;
  color: #FFF;
  font-size: 0.9rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
}
@media screen and (min-width: 375px) {
  .panel-heading {
    font-size: calc(0.9rem + 7 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .panel-heading {
    font-size: 1.25rem;
  }
}
@media screen and (min-width: 375px) {
  .panel-heading {
    padding-top: calc(0.75rem + 10 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .panel-heading {
    padding-top: 1.25rem;
  }
}
@media screen and (min-width: 375px) {
  .panel-heading {
    padding-bottom: calc(0.75rem + 10 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .panel-heading {
    padding-bottom: 1.25rem;
  }
}
@media screen and (min-width: 375px) {
  .panel-heading {
    padding-left: calc(0.6rem + 23 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .panel-heading {
    padding-left: 1.75rem;
  }
}
@media screen and (min-width: 375px) {
  .panel-heading {
    padding-right: calc(0.6rem + 23 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .panel-heading {
    padding-right: 1.75rem;
  }
}
.panel-body,
.panel-footer,
.panel .list-group {
  overflow: hidden;
  border: 1px solid;
}
.panel * + .list-group {
  border-top-width: 0;
}
.panel-primary .panel-heading {
  background-color: #6D54FF;
  color: #FFF;
}
.panel-primary .panel-heading,
.panel-primary .panel-searchbox,
.panel-primary .panel-footer,
.panel-primary .panel-body {
  border-color: #6D54FF;
}
.panel-primary .list-group {
  border-color: #6D54FF;
}
.panel-secondary .panel-heading {
  background-color: #B0B39D;
  color: #FFF;
}
.panel-secondary .panel-heading,
.panel-secondary .panel-searchbox,
.panel-secondary .panel-footer,
.panel-secondary .panel-body {
  border-color: #B0B39D;
}
.panel-secondary .list-group {
  border-color: #B0B39D;
}
.panel-tooltip {
  background-color: #F1F1EE;
  margin: 0;
}
.panel-tooltip .panel-body {
  padding: 1rem;
}
@media screen and (min-width: 375px) {
  .panel-tooltip .panel-body {
    padding: calc(1rem + 10 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .panel-tooltip .panel-body {
    padding: 1.5rem;
  }
}
.dropdown {
  position: relative;
}
.dropdown:hover > .icon {
  color: #6D54FF;
}
.dropdown:hover > input {
  border-color: #6D54FF;
}
.dropdown > .icon {
  position: absolute;
  right: 1rem;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #B0B39D;
  transition: color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  cursor: pointer;
}
@media screen and (min-width: 375px) {
  .dropdown > .icon {
    right: calc(1rem + 5 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .dropdown > .icon {
    right: 1.25rem;
  }
}
.dropdown.open > input {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-color: #6D54FF;
}
.dropdown.open > .icon {
  color: #6D54FF;
}
.dropdown > ul {
  width: 100%;
  padding-top: 0;
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 0;
  border: none;
  border-radius: 10px;
}
.dropdown > ul > li > a {
  border-bottom: 2px solid #6D54FF;
  border-right: 2px solid #6D54FF;
  border-left: 2px solid #6D54FF;
}
.dropdown > ul > li > a:hover {
  background-color: transparent;
}
.dropdown > ul > li:first-child > a {
  border-top: none;
}
.dropdown > ul > li:last-child > a {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.dropdown > ul > li > a {
  font-size: 1rem;
  padding: 0 20px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
@media screen and (min-width: 375px) {
  .dropdown > ul > li > a {
    font-size: calc(1rem + 5 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .dropdown > ul > li > a {
    font-size: 1.25rem;
  }
}
@media screen and (min-width: 375px) {
  .dropdown > ul > li > a {
    padding-top: calc(0.5rem + 5 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .dropdown > ul > li > a {
    padding-top: 0.75rem;
  }
}
@media screen and (min-width: 375px) {
  .dropdown > ul > li > a {
    padding-bottom: calc(0.5rem + 5 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .dropdown > ul > li > a {
    padding-bottom: 0.75rem;
  }
}
.dropdown > ul > li > a:hover {
  color: #6D54FF;
}
.dropdown > input {
  padding-right: 1.75rem;
}
@media screen and (min-width: 375px) {
  .dropdown > input {
    padding-right: calc(1.75rem + 5 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .dropdown > input {
    padding-right: 2rem;
  }
}
.dropdown > input:focus + .icon,
.dropdown > input:hover + .icon {
  color: #6D54FF;
}
@media screen and (max-width: 992px) {
  .navbar-nav .open .dropdown-menu {
    position: absolute;
    width: 100%;
    background-color: #fff;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    color: #3F3D35;
  }
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #6D54FF;
  }
  .navbar-inverse .navbar-nav .open .dropdown-menu > li > a {
    color: #3F3D35;
  }
  .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:hover,
  .navbar-inverse .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #6D54FF;
  }
}
.popover {
  border: 2px solid;
  background-color: #F1F1EE;
  box-shadow: none;
  font-size: 0.9rem;
  padding: 0;
  display: block;
}
@media screen and (min-width: 375px) {
  .popover {
    font-size: calc(0.9rem + 4 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .popover {
    font-size: 1.1rem;
  }
}
.popover-content {
  padding: 0;
}
.popover.top {
  margin-bottom: 0.75rem;
}
.popover.right {
  margin-left: 0.75rem;
}
.popover.bottom {
  margin-top: 0.75rem;
}
.popover.left {
  margin-left: -0.75rem;
}
.popover .panel-body {
  border: none;
}
@media screen and (max-width: 991px) {
  .popover {
    width: 100%;
    max-width: 100%;
  }
}
@media screen and (min-width: 992px) {
  .popover {
    display: inline-block;
    width: auto;
  }
  .popover.top {
    margin-bottom: 1rem;
  }
  .popover.right {
    margin-left: 1rem;
  }
  .popover.bottom {
    margin-top: 1rem;
  }
  .popover.left {
    margin-left: -1rem;
  }
}
.popover {
  border-color: #B0B39D;
}
.popover.top > .arrow:after {
  border-top-color: #B0B39D;
}
.popover.right > .arrow:after {
  border-right-color: #B0B39D;
}
.popover.bottom > .arrow:after {
  border-bottom-color: #B0B39D;
}
.popover.left > .arrow:after {
  border-left-color: #B0B39D;
}
.popover.open {
  border-color: #6D54FF;
}
.popover.open.top > .arrow:after {
  border-top-color: #6D54FF;
}
.popover.open.right > .arrow:after {
  border-right-color: #6D54FF;
}
.popover.open.bottom > .arrow:after {
  border-bottom-color: #6D54FF;
}
.popover.open.left > .arrow:after {
  border-left-color: #6D54FF;
}
.navbar-fixed-top,
.navbar-fixed-bottom {
  z-index: 1070;
}
.navbar-default,
.navbar-inverse {
  box-shadow: 0 0 30px -10px black;
  padding: 0.8rem;
}
@media screen and (min-width: 375px) {
  .navbar-default,
  .navbar-inverse {
    padding: calc(0.8rem + 0 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .navbar-default,
  .navbar-inverse {
    padding: 0.8rem;
  }
}
.navbar-default {
  background-color: #fff;
}
.navbar-default .btn-link.open,
.navbar-default .btn-link:active,
.navbar-default .btn-link:hover,
.navbar-default .btn-link:focus,
.navbar-default .btn-link:active:hover,
.navbar-default .btn-linkactive:focus {
  color: #6D54FF;
}
.navbar-inverse {
  background-color: #6D54FF;
  border-color: transparent;
  color: white;
}
.navbar-inverse .btn {
  color: #fff;
}
.navbar-inverse .btn.open,
.navbar-inverse .btn:active,
.navbar-inverse .btn:hover,
.navbar-inverse .btn:focus,
.navbar-inverse .btn:active:hover,
.navbar-inverse .btnactive:focus {
  color: #fff;
  background-color: #6D54FF;
  border-color: transparent;
}
.icon-progress {
  display: block;
  position: relative;
  border-radius: 50%;
  font-size: 0.65rem;
  width: 1em;
  height: 1em;
  background-color: #3F3D35;
}
@media screen and (min-width: 375px) {
  .icon-progress {
    font-size: calc(0.65rem + 3 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .icon-progress {
    font-size: 0.8rem;
  }
}
.icon-progress-filler {
  box-sizing: content-box;
  position: absolute;
  width: 1em;
  height: 1em;
}
.icon-progress-filler:before,
.icon-progress-filler:after {
  content: "";
  display: block;
  position: absolute;
  border: 0.5em solid #F1F1EE;
  width: 0;
  height: 0;
  clip: rect(0, 0.5em, 1.08em, 0);
  border-radius: 50%;
  box-sizing: content-box;
}
.icon-progress.progress-0 > .icon-progress-filler {
  clip: rect(0, 1.08em, 1.08em, 0.5em);
}
.icon-progress.progress-0 > .icon-progress-filler:before {
  -ms-transform: rotate(0deg) scale(1.08);
  transform: rotate(0deg) scale(1.08);
}
.icon-progress.progress-10 > .icon-progress-filler {
  clip: rect(0, 1.08em, 1.08em, 0.5em);
}
.icon-progress.progress-10 > .icon-progress-filler:before {
  -ms-transform: rotate(36deg) scale(1.08);
  transform: rotate(36deg) scale(1.08);
}
.icon-progress.progress-20 > .icon-progress-filler {
  clip: rect(0, 1.08em, 1.08em, 0.5em);
}
.icon-progress.progress-20 > .icon-progress-filler:before {
  -ms-transform: rotate(72deg) scale(1.08);
  transform: rotate(72deg) scale(1.08);
}
.icon-progress.progress-30 > .icon-progress-filler {
  clip: rect(0, 1.08em, 1.08em, 0.5em);
}
.icon-progress.progress-30 > .icon-progress-filler:before {
  -ms-transform: rotate(108deg) scale(1.08);
  transform: rotate(108deg) scale(1.08);
}
.icon-progress.progress-40 > .icon-progress-filler {
  clip: rect(0, 1.08em, 1.08em, 0.5em);
}
.icon-progress.progress-40 > .icon-progress-filler:before {
  -ms-transform: rotate(144deg) scale(1.08);
  transform: rotate(144deg) scale(1.08);
}
.icon-progress.progress-50 > .icon-progress-filler {
  clip: rect(0, 1.08em, 1.08em, 0.5em);
}
.icon-progress.progress-50 > .icon-progress-filler:before {
  -ms-transform: rotate(180deg) scale(1.08);
  transform: rotate(180deg) scale(1.08);
}
.icon-progress.progress-60 > .icon-progress-filler {
  clip: rect(auto, auto, auto, auto);
}
.icon-progress.progress-60 > .icon-progress-filler:before {
  -ms-transform: rotate(216deg) scale(1.08);
  transform: rotate(216deg) scale(1.08);
}
.icon-progress.progress-60 > .icon-progress-filler:after {
  -ms-transform: rotate(180deg) scale(1.08);
  transform: rotate(180deg) scale(1.08);
}
.icon-progress.progress-70 > .icon-progress-filler {
  clip: rect(auto, auto, auto, auto);
}
.icon-progress.progress-70 > .icon-progress-filler:before {
  -ms-transform: rotate(252deg) scale(1.08);
  transform: rotate(252deg) scale(1.08);
}
.icon-progress.progress-70 > .icon-progress-filler:after {
  -ms-transform: rotate(180deg) scale(1.08);
  transform: rotate(180deg) scale(1.08);
}
.icon-progress.progress-80 > .icon-progress-filler {
  clip: rect(auto, auto, auto, auto);
}
.icon-progress.progress-80 > .icon-progress-filler:before {
  -ms-transform: rotate(288deg) scale(1.08);
  transform: rotate(288deg) scale(1.08);
}
.icon-progress.progress-80 > .icon-progress-filler:after {
  -ms-transform: rotate(180deg) scale(1.08);
  transform: rotate(180deg) scale(1.08);
}
.icon-progress.progress-90 > .icon-progress-filler {
  clip: rect(auto, auto, auto, auto);
}
.icon-progress.progress-90 > .icon-progress-filler:before {
  -ms-transform: rotate(324deg) scale(1.08);
  transform: rotate(324deg) scale(1.08);
}
.icon-progress.progress-90 > .icon-progress-filler:after {
  -ms-transform: rotate(180deg) scale(1.08);
  transform: rotate(180deg) scale(1.08);
}
.icon-progress.progress-100 > .icon-progress-filler {
  clip: rect(auto, auto, auto, auto);
}
.icon-progress.progress-100 > .icon-progress-filler:before {
  -ms-transform: rotate(360deg) scale(1.08);
  transform: rotate(360deg) scale(1.08);
}
.icon-progress.progress-100 > .icon-progress-filler:after {
  -ms-transform: rotate(180deg) scale(1.08);
  transform: rotate(180deg) scale(1.08);
}
.progress-bar-container {
  position: relative;
  margin-top: 0.6rem;
}
@media screen and (min-width: 992px) {
  .progress-bar-container {
    margin-top: calc(0.6rem + 5 * ((100vw - 992px) / 928));
  }
}
@media screen and (min-width: 1920px) {
  .progress-bar-container {
    margin-top: 0.85rem;
  }
}
.progress-bar-container:before {
  display: block;
  float: left;
  font-weight: bold;
  font-size: 0.9rem;
  color: #6D54FF;
  margin-right: 0.5rem;
}
@media screen and (min-width: 480px) {
  .progress-bar-container:before {
    font-size: calc(0.9rem + 4 * ((100vw - 480px) / 512));
  }
}
@media screen and (min-width: 992px) {
  .progress-bar-container:before {
    font-size: 1.1rem;
  }
}
@media screen and (min-width: 480px) {
  .progress-bar-container:before {
    margin-right: calc(0.5rem + 15 * ((100vw - 480px) / 512));
  }
}
@media screen and (min-width: 992px) {
  .progress-bar-container:before {
    margin-right: 1.25rem;
  }
}
.progress-bar-container.progress-0:before {
  content: "0%";
}
.progress-bar-container.progress-0 > .progress > .progress-bar {
  width: 0%;
}
.progress-bar-container.progress-0:before {
  color: #B0B39D;
}
.progress-bar-container.progress-1:before {
  content: "1%";
}
.progress-bar-container.progress-1 > .progress > .progress-bar {
  width: 1%;
}
.progress-bar-container.progress-2:before {
  content: "2%";
}
.progress-bar-container.progress-2 > .progress > .progress-bar {
  width: 2%;
}
.progress-bar-container.progress-3:before {
  content: "3%";
}
.progress-bar-container.progress-3 > .progress > .progress-bar {
  width: 3%;
}
.progress-bar-container.progress-4:before {
  content: "4%";
}
.progress-bar-container.progress-4 > .progress > .progress-bar {
  width: 4%;
}
.progress-bar-container.progress-5:before {
  content: "5%";
}
.progress-bar-container.progress-5 > .progress > .progress-bar {
  width: 5%;
}
.progress-bar-container.progress-6:before {
  content: "6%";
}
.progress-bar-container.progress-6 > .progress > .progress-bar {
  width: 6%;
}
.progress-bar-container.progress-7:before {
  content: "7%";
}
.progress-bar-container.progress-7 > .progress > .progress-bar {
  width: 7%;
}
.progress-bar-container.progress-8:before {
  content: "8%";
}
.progress-bar-container.progress-8 > .progress > .progress-bar {
  width: 8%;
}
.progress-bar-container.progress-9:before {
  content: "9%";
}
.progress-bar-container.progress-9 > .progress > .progress-bar {
  width: 9%;
}
.progress-bar-container.progress-10:before {
  content: "10%";
}
.progress-bar-container.progress-10 > .progress > .progress-bar {
  width: 10%;
}
.progress-bar-container.progress-11:before {
  content: "11%";
}
.progress-bar-container.progress-11 > .progress > .progress-bar {
  width: 11%;
}
.progress-bar-container.progress-12:before {
  content: "12%";
}
.progress-bar-container.progress-12 > .progress > .progress-bar {
  width: 12%;
}
.progress-bar-container.progress-13:before {
  content: "13%";
}
.progress-bar-container.progress-13 > .progress > .progress-bar {
  width: 13%;
}
.progress-bar-container.progress-14:before {
  content: "14%";
}
.progress-bar-container.progress-14 > .progress > .progress-bar {
  width: 14%;
}
.progress-bar-container.progress-15:before {
  content: "15%";
}
.progress-bar-container.progress-15 > .progress > .progress-bar {
  width: 15%;
}
.progress-bar-container.progress-16:before {
  content: "16%";
}
.progress-bar-container.progress-16 > .progress > .progress-bar {
  width: 16%;
}
.progress-bar-container.progress-17:before {
  content: "17%";
}
.progress-bar-container.progress-17 > .progress > .progress-bar {
  width: 17%;
}
.progress-bar-container.progress-18:before {
  content: "18%";
}
.progress-bar-container.progress-18 > .progress > .progress-bar {
  width: 18%;
}
.progress-bar-container.progress-19:before {
  content: "19%";
}
.progress-bar-container.progress-19 > .progress > .progress-bar {
  width: 19%;
}
.progress-bar-container.progress-20:before {
  content: "20%";
}
.progress-bar-container.progress-20 > .progress > .progress-bar {
  width: 20%;
}
.progress-bar-container.progress-21:before {
  content: "21%";
}
.progress-bar-container.progress-21 > .progress > .progress-bar {
  width: 21%;
}
.progress-bar-container.progress-22:before {
  content: "22%";
}
.progress-bar-container.progress-22 > .progress > .progress-bar {
  width: 22%;
}
.progress-bar-container.progress-23:before {
  content: "23%";
}
.progress-bar-container.progress-23 > .progress > .progress-bar {
  width: 23%;
}
.progress-bar-container.progress-24:before {
  content: "24%";
}
.progress-bar-container.progress-24 > .progress > .progress-bar {
  width: 24%;
}
.progress-bar-container.progress-25:before {
  content: "25%";
}
.progress-bar-container.progress-25 > .progress > .progress-bar {
  width: 25%;
}
.progress-bar-container.progress-26:before {
  content: "26%";
}
.progress-bar-container.progress-26 > .progress > .progress-bar {
  width: 26%;
}
.progress-bar-container.progress-27:before {
  content: "27%";
}
.progress-bar-container.progress-27 > .progress > .progress-bar {
  width: 27%;
}
.progress-bar-container.progress-28:before {
  content: "28%";
}
.progress-bar-container.progress-28 > .progress > .progress-bar {
  width: 28%;
}
.progress-bar-container.progress-29:before {
  content: "29%";
}
.progress-bar-container.progress-29 > .progress > .progress-bar {
  width: 29%;
}
.progress-bar-container.progress-30:before {
  content: "30%";
}
.progress-bar-container.progress-30 > .progress > .progress-bar {
  width: 30%;
}
.progress-bar-container.progress-31:before {
  content: "31%";
}
.progress-bar-container.progress-31 > .progress > .progress-bar {
  width: 31%;
}
.progress-bar-container.progress-32:before {
  content: "32%";
}
.progress-bar-container.progress-32 > .progress > .progress-bar {
  width: 32%;
}
.progress-bar-container.progress-33:before {
  content: "33%";
}
.progress-bar-container.progress-33 > .progress > .progress-bar {
  width: 33%;
}
.progress-bar-container.progress-34:before {
  content: "34%";
}
.progress-bar-container.progress-34 > .progress > .progress-bar {
  width: 34%;
}
.progress-bar-container.progress-35:before {
  content: "35%";
}
.progress-bar-container.progress-35 > .progress > .progress-bar {
  width: 35%;
}
.progress-bar-container.progress-36:before {
  content: "36%";
}
.progress-bar-container.progress-36 > .progress > .progress-bar {
  width: 36%;
}
.progress-bar-container.progress-37:before {
  content: "37%";
}
.progress-bar-container.progress-37 > .progress > .progress-bar {
  width: 37%;
}
.progress-bar-container.progress-38:before {
  content: "38%";
}
.progress-bar-container.progress-38 > .progress > .progress-bar {
  width: 38%;
}
.progress-bar-container.progress-39:before {
  content: "39%";
}
.progress-bar-container.progress-39 > .progress > .progress-bar {
  width: 39%;
}
.progress-bar-container.progress-40:before {
  content: "40%";
}
.progress-bar-container.progress-40 > .progress > .progress-bar {
  width: 40%;
}
.progress-bar-container.progress-41:before {
  content: "41%";
}
.progress-bar-container.progress-41 > .progress > .progress-bar {
  width: 41%;
}
.progress-bar-container.progress-42:before {
  content: "42%";
}
.progress-bar-container.progress-42 > .progress > .progress-bar {
  width: 42%;
}
.progress-bar-container.progress-43:before {
  content: "43%";
}
.progress-bar-container.progress-43 > .progress > .progress-bar {
  width: 43%;
}
.progress-bar-container.progress-44:before {
  content: "44%";
}
.progress-bar-container.progress-44 > .progress > .progress-bar {
  width: 44%;
}
.progress-bar-container.progress-45:before {
  content: "45%";
}
.progress-bar-container.progress-45 > .progress > .progress-bar {
  width: 45%;
}
.progress-bar-container.progress-46:before {
  content: "46%";
}
.progress-bar-container.progress-46 > .progress > .progress-bar {
  width: 46%;
}
.progress-bar-container.progress-47:before {
  content: "47%";
}
.progress-bar-container.progress-47 > .progress > .progress-bar {
  width: 47%;
}
.progress-bar-container.progress-48:before {
  content: "48%";
}
.progress-bar-container.progress-48 > .progress > .progress-bar {
  width: 48%;
}
.progress-bar-container.progress-49:before {
  content: "49%";
}
.progress-bar-container.progress-49 > .progress > .progress-bar {
  width: 49%;
}
.progress-bar-container.progress-50:before {
  content: "50%";
}
.progress-bar-container.progress-50 > .progress > .progress-bar {
  width: 50%;
}
.progress-bar-container.progress-51:before {
  content: "51%";
}
.progress-bar-container.progress-51 > .progress > .progress-bar {
  width: 51%;
}
.progress-bar-container.progress-52:before {
  content: "52%";
}
.progress-bar-container.progress-52 > .progress > .progress-bar {
  width: 52%;
}
.progress-bar-container.progress-53:before {
  content: "53%";
}
.progress-bar-container.progress-53 > .progress > .progress-bar {
  width: 53%;
}
.progress-bar-container.progress-54:before {
  content: "54%";
}
.progress-bar-container.progress-54 > .progress > .progress-bar {
  width: 54%;
}
.progress-bar-container.progress-55:before {
  content: "55%";
}
.progress-bar-container.progress-55 > .progress > .progress-bar {
  width: 55%;
}
.progress-bar-container.progress-56:before {
  content: "56%";
}
.progress-bar-container.progress-56 > .progress > .progress-bar {
  width: 56%;
}
.progress-bar-container.progress-57:before {
  content: "57%";
}
.progress-bar-container.progress-57 > .progress > .progress-bar {
  width: 57%;
}
.progress-bar-container.progress-58:before {
  content: "58%";
}
.progress-bar-container.progress-58 > .progress > .progress-bar {
  width: 58%;
}
.progress-bar-container.progress-59:before {
  content: "59%";
}
.progress-bar-container.progress-59 > .progress > .progress-bar {
  width: 59%;
}
.progress-bar-container.progress-60:before {
  content: "60%";
}
.progress-bar-container.progress-60 > .progress > .progress-bar {
  width: 60%;
}
.progress-bar-container.progress-61:before {
  content: "61%";
}
.progress-bar-container.progress-61 > .progress > .progress-bar {
  width: 61%;
}
.progress-bar-container.progress-62:before {
  content: "62%";
}
.progress-bar-container.progress-62 > .progress > .progress-bar {
  width: 62%;
}
.progress-bar-container.progress-63:before {
  content: "63%";
}
.progress-bar-container.progress-63 > .progress > .progress-bar {
  width: 63%;
}
.progress-bar-container.progress-64:before {
  content: "64%";
}
.progress-bar-container.progress-64 > .progress > .progress-bar {
  width: 64%;
}
.progress-bar-container.progress-65:before {
  content: "65%";
}
.progress-bar-container.progress-65 > .progress > .progress-bar {
  width: 65%;
}
.progress-bar-container.progress-66:before {
  content: "66%";
}
.progress-bar-container.progress-66 > .progress > .progress-bar {
  width: 66%;
}
.progress-bar-container.progress-67:before {
  content: "67%";
}
.progress-bar-container.progress-67 > .progress > .progress-bar {
  width: 67%;
}
.progress-bar-container.progress-68:before {
  content: "68%";
}
.progress-bar-container.progress-68 > .progress > .progress-bar {
  width: 68%;
}
.progress-bar-container.progress-69:before {
  content: "69%";
}
.progress-bar-container.progress-69 > .progress > .progress-bar {
  width: 69%;
}
.progress-bar-container.progress-70:before {
  content: "70%";
}
.progress-bar-container.progress-70 > .progress > .progress-bar {
  width: 70%;
}
.progress-bar-container.progress-71:before {
  content: "71%";
}
.progress-bar-container.progress-71 > .progress > .progress-bar {
  width: 71%;
}
.progress-bar-container.progress-72:before {
  content: "72%";
}
.progress-bar-container.progress-72 > .progress > .progress-bar {
  width: 72%;
}
.progress-bar-container.progress-73:before {
  content: "73%";
}
.progress-bar-container.progress-73 > .progress > .progress-bar {
  width: 73%;
}
.progress-bar-container.progress-74:before {
  content: "74%";
}
.progress-bar-container.progress-74 > .progress > .progress-bar {
  width: 74%;
}
.progress-bar-container.progress-75:before {
  content: "75%";
}
.progress-bar-container.progress-75 > .progress > .progress-bar {
  width: 75%;
}
.progress-bar-container.progress-76:before {
  content: "76%";
}
.progress-bar-container.progress-76 > .progress > .progress-bar {
  width: 76%;
}
.progress-bar-container.progress-77:before {
  content: "77%";
}
.progress-bar-container.progress-77 > .progress > .progress-bar {
  width: 77%;
}
.progress-bar-container.progress-78:before {
  content: "78%";
}
.progress-bar-container.progress-78 > .progress > .progress-bar {
  width: 78%;
}
.progress-bar-container.progress-79:before {
  content: "79%";
}
.progress-bar-container.progress-79 > .progress > .progress-bar {
  width: 79%;
}
.progress-bar-container.progress-80:before {
  content: "80%";
}
.progress-bar-container.progress-80 > .progress > .progress-bar {
  width: 80%;
}
.progress-bar-container.progress-81:before {
  content: "81%";
}
.progress-bar-container.progress-81 > .progress > .progress-bar {
  width: 81%;
}
.progress-bar-container.progress-82:before {
  content: "82%";
}
.progress-bar-container.progress-82 > .progress > .progress-bar {
  width: 82%;
}
.progress-bar-container.progress-83:before {
  content: "83%";
}
.progress-bar-container.progress-83 > .progress > .progress-bar {
  width: 83%;
}
.progress-bar-container.progress-84:before {
  content: "84%";
}
.progress-bar-container.progress-84 > .progress > .progress-bar {
  width: 84%;
}
.progress-bar-container.progress-85:before {
  content: "85%";
}
.progress-bar-container.progress-85 > .progress > .progress-bar {
  width: 85%;
}
.progress-bar-container.progress-86:before {
  content: "86%";
}
.progress-bar-container.progress-86 > .progress > .progress-bar {
  width: 86%;
}
.progress-bar-container.progress-87:before {
  content: "87%";
}
.progress-bar-container.progress-87 > .progress > .progress-bar {
  width: 87%;
}
.progress-bar-container.progress-88:before {
  content: "88%";
}
.progress-bar-container.progress-88 > .progress > .progress-bar {
  width: 88%;
}
.progress-bar-container.progress-89:before {
  content: "89%";
}
.progress-bar-container.progress-89 > .progress > .progress-bar {
  width: 89%;
}
.progress-bar-container.progress-90:before {
  content: "90%";
}
.progress-bar-container.progress-90 > .progress > .progress-bar {
  width: 90%;
}
.progress-bar-container.progress-91:before {
  content: "91%";
}
.progress-bar-container.progress-91 > .progress > .progress-bar {
  width: 91%;
}
.progress-bar-container.progress-92:before {
  content: "92%";
}
.progress-bar-container.progress-92 > .progress > .progress-bar {
  width: 92%;
}
.progress-bar-container.progress-93:before {
  content: "93%";
}
.progress-bar-container.progress-93 > .progress > .progress-bar {
  width: 93%;
}
.progress-bar-container.progress-94:before {
  content: "94%";
}
.progress-bar-container.progress-94 > .progress > .progress-bar {
  width: 94%;
}
.progress-bar-container.progress-95:before {
  content: "95%";
}
.progress-bar-container.progress-95 > .progress > .progress-bar {
  width: 95%;
}
.progress-bar-container.progress-96:before {
  content: "96%";
}
.progress-bar-container.progress-96 > .progress > .progress-bar {
  width: 96%;
}
.progress-bar-container.progress-97:before {
  content: "97%";
}
.progress-bar-container.progress-97 > .progress > .progress-bar {
  width: 97%;
}
.progress-bar-container.progress-98:before {
  content: "98%";
}
.progress-bar-container.progress-98 > .progress > .progress-bar {
  width: 98%;
}
.progress-bar-container.progress-99:before {
  content: "99%";
}
.progress-bar-container.progress-99 > .progress > .progress-bar {
  width: 99%;
}
.progress-bar-container.progress-100:before {
  content: "100%";
}
.progress-bar-container.progress-100 > .progress > .progress-bar {
  width: 100%;
}
.progress-bar-container > .progress {
  display: block;
  margin-bottom: 0;
  margin-right: 0;
  border-radius: 0;
  background-color: #B0B39D;
  font-size: 0.9rem;
  position: relative;
}
@media screen and (min-width: 480px) {
  .progress-bar-container > .progress {
    font-size: calc(0.9rem + 4 * ((100vw - 480px) / 512));
  }
}
@media screen and (min-width: 992px) {
  .progress-bar-container > .progress {
    font-size: 1.1rem;
  }
}
.progress-bar-container > .progress > .progress-bar {
  background-color: #6D54FF;
}
.progress-bar-container.progress-1:before {
  content: "1%";
}
.progress-bar-container.progress-1 > .progress > .progress-bar {
  width: 1%;
}
.progress-bar-container.progress-2:before {
  content: "2%";
}
.progress-bar-container.progress-2 > .progress > .progress-bar {
  width: 2%;
}
.progress-bar-container.progress-3:before {
  content: "3%";
}
.progress-bar-container.progress-3 > .progress > .progress-bar {
  width: 3%;
}
.progress-bar-container.progress-4:before {
  content: "4%";
}
.progress-bar-container.progress-4 > .progress > .progress-bar {
  width: 4%;
}
.progress-bar-container.progress-5:before {
  content: "5%";
}
.progress-bar-container.progress-5 > .progress > .progress-bar {
  width: 5%;
}
.progress-bar-container.progress-6:before {
  content: "6%";
}
.progress-bar-container.progress-6 > .progress > .progress-bar {
  width: 6%;
}
.progress-bar-container.progress-7:before {
  content: "7%";
}
.progress-bar-container.progress-7 > .progress > .progress-bar {
  width: 7%;
}
.progress-bar-container.progress-8:before {
  content: "8%";
}
.progress-bar-container.progress-8 > .progress > .progress-bar {
  width: 8%;
}
.progress-bar-container.progress-9:before {
  content: "9%";
}
.progress-bar-container.progress-9 > .progress > .progress-bar {
  width: 9%;
}
.progress-bar-container.progress-10:before {
  content: "10%";
}
.progress-bar-container.progress-10 > .progress > .progress-bar {
  width: 10%;
}
.progress-bar-container.progress-11:before {
  content: "11%";
}
.progress-bar-container.progress-11 > .progress > .progress-bar {
  width: 11%;
}
.progress-bar-container.progress-12:before {
  content: "12%";
}
.progress-bar-container.progress-12 > .progress > .progress-bar {
  width: 12%;
}
.progress-bar-container.progress-13:before {
  content: "13%";
}
.progress-bar-container.progress-13 > .progress > .progress-bar {
  width: 13%;
}
.progress-bar-container.progress-14:before {
  content: "14%";
}
.progress-bar-container.progress-14 > .progress > .progress-bar {
  width: 14%;
}
.progress-bar-container.progress-15:before {
  content: "15%";
}
.progress-bar-container.progress-15 > .progress > .progress-bar {
  width: 15%;
}
.progress-bar-container.progress-16:before {
  content: "16%";
}
.progress-bar-container.progress-16 > .progress > .progress-bar {
  width: 16%;
}
.progress-bar-container.progress-17:before {
  content: "17%";
}
.progress-bar-container.progress-17 > .progress > .progress-bar {
  width: 17%;
}
.progress-bar-container.progress-18:before {
  content: "18%";
}
.progress-bar-container.progress-18 > .progress > .progress-bar {
  width: 18%;
}
.progress-bar-container.progress-19:before {
  content: "19%";
}
.progress-bar-container.progress-19 > .progress > .progress-bar {
  width: 19%;
}
.progress-bar-container.progress-20:before {
  content: "20%";
}
.progress-bar-container.progress-20 > .progress > .progress-bar {
  width: 20%;
}
.progress-bar-container.progress-21:before {
  content: "21%";
}
.progress-bar-container.progress-21 > .progress > .progress-bar {
  width: 21%;
}
.progress-bar-container.progress-22:before {
  content: "22%";
}
.progress-bar-container.progress-22 > .progress > .progress-bar {
  width: 22%;
}
.progress-bar-container.progress-23:before {
  content: "23%";
}
.progress-bar-container.progress-23 > .progress > .progress-bar {
  width: 23%;
}
.progress-bar-container.progress-24:before {
  content: "24%";
}
.progress-bar-container.progress-24 > .progress > .progress-bar {
  width: 24%;
}
.progress-bar-container.progress-25:before {
  content: "25%";
}
.progress-bar-container.progress-25 > .progress > .progress-bar {
  width: 25%;
}
.progress-bar-container.progress-26:before {
  content: "26%";
}
.progress-bar-container.progress-26 > .progress > .progress-bar {
  width: 26%;
}
.progress-bar-container.progress-27:before {
  content: "27%";
}
.progress-bar-container.progress-27 > .progress > .progress-bar {
  width: 27%;
}
.progress-bar-container.progress-28:before {
  content: "28%";
}
.progress-bar-container.progress-28 > .progress > .progress-bar {
  width: 28%;
}
.progress-bar-container.progress-29:before {
  content: "29%";
}
.progress-bar-container.progress-29 > .progress > .progress-bar {
  width: 29%;
}
.progress-bar-container.progress-30:before {
  content: "30%";
}
.progress-bar-container.progress-30 > .progress > .progress-bar {
  width: 30%;
}
.progress-bar-container.progress-31:before {
  content: "31%";
}
.progress-bar-container.progress-31 > .progress > .progress-bar {
  width: 31%;
}
.progress-bar-container.progress-32:before {
  content: "32%";
}
.progress-bar-container.progress-32 > .progress > .progress-bar {
  width: 32%;
}
.progress-bar-container.progress-33:before {
  content: "33%";
}
.progress-bar-container.progress-33 > .progress > .progress-bar {
  width: 33%;
}
.progress-bar-container.progress-34:before {
  content: "34%";
}
.progress-bar-container.progress-34 > .progress > .progress-bar {
  width: 34%;
}
.progress-bar-container.progress-35:before {
  content: "35%";
}
.progress-bar-container.progress-35 > .progress > .progress-bar {
  width: 35%;
}
.progress-bar-container.progress-36:before {
  content: "36%";
}
.progress-bar-container.progress-36 > .progress > .progress-bar {
  width: 36%;
}
.progress-bar-container.progress-37:before {
  content: "37%";
}
.progress-bar-container.progress-37 > .progress > .progress-bar {
  width: 37%;
}
.progress-bar-container.progress-38:before {
  content: "38%";
}
.progress-bar-container.progress-38 > .progress > .progress-bar {
  width: 38%;
}
.progress-bar-container.progress-39:before {
  content: "39%";
}
.progress-bar-container.progress-39 > .progress > .progress-bar {
  width: 39%;
}
.progress-bar-container.progress-40:before {
  content: "40%";
}
.progress-bar-container.progress-40 > .progress > .progress-bar {
  width: 40%;
}
.progress-bar-container.progress-41:before {
  content: "41%";
}
.progress-bar-container.progress-41 > .progress > .progress-bar {
  width: 41%;
}
.progress-bar-container.progress-42:before {
  content: "42%";
}
.progress-bar-container.progress-42 > .progress > .progress-bar {
  width: 42%;
}
.progress-bar-container.progress-43:before {
  content: "43%";
}
.progress-bar-container.progress-43 > .progress > .progress-bar {
  width: 43%;
}
.progress-bar-container.progress-44:before {
  content: "44%";
}
.progress-bar-container.progress-44 > .progress > .progress-bar {
  width: 44%;
}
.progress-bar-container.progress-45:before {
  content: "45%";
}
.progress-bar-container.progress-45 > .progress > .progress-bar {
  width: 45%;
}
.progress-bar-container.progress-46:before {
  content: "46%";
}
.progress-bar-container.progress-46 > .progress > .progress-bar {
  width: 46%;
}
.progress-bar-container.progress-47:before {
  content: "47%";
}
.progress-bar-container.progress-47 > .progress > .progress-bar {
  width: 47%;
}
.progress-bar-container.progress-48:before {
  content: "48%";
}
.progress-bar-container.progress-48 > .progress > .progress-bar {
  width: 48%;
}
.progress-bar-container.progress-49:before {
  content: "49%";
}
.progress-bar-container.progress-49 > .progress > .progress-bar {
  width: 49%;
}
.progress-bar-container.progress-50:before {
  content: "50%";
}
.progress-bar-container.progress-50 > .progress > .progress-bar {
  width: 50%;
}
.progress-bar-container.progress-51:before {
  content: "51%";
}
.progress-bar-container.progress-51 > .progress > .progress-bar {
  width: 51%;
}
.progress-bar-container.progress-52:before {
  content: "52%";
}
.progress-bar-container.progress-52 > .progress > .progress-bar {
  width: 52%;
}
.progress-bar-container.progress-53:before {
  content: "53%";
}
.progress-bar-container.progress-53 > .progress > .progress-bar {
  width: 53%;
}
.progress-bar-container.progress-54:before {
  content: "54%";
}
.progress-bar-container.progress-54 > .progress > .progress-bar {
  width: 54%;
}
.progress-bar-container.progress-55:before {
  content: "55%";
}
.progress-bar-container.progress-55 > .progress > .progress-bar {
  width: 55%;
}
.progress-bar-container.progress-56:before {
  content: "56%";
}
.progress-bar-container.progress-56 > .progress > .progress-bar {
  width: 56%;
}
.progress-bar-container.progress-57:before {
  content: "57%";
}
.progress-bar-container.progress-57 > .progress > .progress-bar {
  width: 57%;
}
.progress-bar-container.progress-58:before {
  content: "58%";
}
.progress-bar-container.progress-58 > .progress > .progress-bar {
  width: 58%;
}
.progress-bar-container.progress-59:before {
  content: "59%";
}
.progress-bar-container.progress-59 > .progress > .progress-bar {
  width: 59%;
}
.progress-bar-container.progress-60:before {
  content: "60%";
}
.progress-bar-container.progress-60 > .progress > .progress-bar {
  width: 60%;
}
.progress-bar-container.progress-61:before {
  content: "61%";
}
.progress-bar-container.progress-61 > .progress > .progress-bar {
  width: 61%;
}
.progress-bar-container.progress-62:before {
  content: "62%";
}
.progress-bar-container.progress-62 > .progress > .progress-bar {
  width: 62%;
}
.progress-bar-container.progress-63:before {
  content: "63%";
}
.progress-bar-container.progress-63 > .progress > .progress-bar {
  width: 63%;
}
.progress-bar-container.progress-64:before {
  content: "64%";
}
.progress-bar-container.progress-64 > .progress > .progress-bar {
  width: 64%;
}
.progress-bar-container.progress-65:before {
  content: "65%";
}
.progress-bar-container.progress-65 > .progress > .progress-bar {
  width: 65%;
}
.progress-bar-container.progress-66:before {
  content: "66%";
}
.progress-bar-container.progress-66 > .progress > .progress-bar {
  width: 66%;
}
.progress-bar-container.progress-67:before {
  content: "67%";
}
.progress-bar-container.progress-67 > .progress > .progress-bar {
  width: 67%;
}
.progress-bar-container.progress-68:before {
  content: "68%";
}
.progress-bar-container.progress-68 > .progress > .progress-bar {
  width: 68%;
}
.progress-bar-container.progress-69:before {
  content: "69%";
}
.progress-bar-container.progress-69 > .progress > .progress-bar {
  width: 69%;
}
.progress-bar-container.progress-70:before {
  content: "70%";
}
.progress-bar-container.progress-70 > .progress > .progress-bar {
  width: 70%;
}
.progress-bar-container.progress-71:before {
  content: "71%";
}
.progress-bar-container.progress-71 > .progress > .progress-bar {
  width: 71%;
}
.progress-bar-container.progress-72:before {
  content: "72%";
}
.progress-bar-container.progress-72 > .progress > .progress-bar {
  width: 72%;
}
.progress-bar-container.progress-73:before {
  content: "73%";
}
.progress-bar-container.progress-73 > .progress > .progress-bar {
  width: 73%;
}
.progress-bar-container.progress-74:before {
  content: "74%";
}
.progress-bar-container.progress-74 > .progress > .progress-bar {
  width: 74%;
}
.progress-bar-container.progress-75:before {
  content: "75%";
}
.progress-bar-container.progress-75 > .progress > .progress-bar {
  width: 75%;
}
.progress-bar-container.progress-76:before {
  content: "76%";
}
.progress-bar-container.progress-76 > .progress > .progress-bar {
  width: 76%;
}
.progress-bar-container.progress-77:before {
  content: "77%";
}
.progress-bar-container.progress-77 > .progress > .progress-bar {
  width: 77%;
}
.progress-bar-container.progress-78:before {
  content: "78%";
}
.progress-bar-container.progress-78 > .progress > .progress-bar {
  width: 78%;
}
.progress-bar-container.progress-79:before {
  content: "79%";
}
.progress-bar-container.progress-79 > .progress > .progress-bar {
  width: 79%;
}
.progress-bar-container.progress-80:before {
  content: "80%";
}
.progress-bar-container.progress-80 > .progress > .progress-bar {
  width: 80%;
}
.progress-bar-container.progress-81:before {
  content: "81%";
}
.progress-bar-container.progress-81 > .progress > .progress-bar {
  width: 81%;
}
.progress-bar-container.progress-82:before {
  content: "82%";
}
.progress-bar-container.progress-82 > .progress > .progress-bar {
  width: 82%;
}
.progress-bar-container.progress-83:before {
  content: "83%";
}
.progress-bar-container.progress-83 > .progress > .progress-bar {
  width: 83%;
}
.progress-bar-container.progress-84:before {
  content: "84%";
}
.progress-bar-container.progress-84 > .progress > .progress-bar {
  width: 84%;
}
.progress-bar-container.progress-85:before {
  content: "85%";
}
.progress-bar-container.progress-85 > .progress > .progress-bar {
  width: 85%;
}
.progress-bar-container.progress-86:before {
  content: "86%";
}
.progress-bar-container.progress-86 > .progress > .progress-bar {
  width: 86%;
}
.progress-bar-container.progress-87:before {
  content: "87%";
}
.progress-bar-container.progress-87 > .progress > .progress-bar {
  width: 87%;
}
.progress-bar-container.progress-88:before {
  content: "88%";
}
.progress-bar-container.progress-88 > .progress > .progress-bar {
  width: 88%;
}
.progress-bar-container.progress-89:before {
  content: "89%";
}
.progress-bar-container.progress-89 > .progress > .progress-bar {
  width: 89%;
}
.progress-bar-container.progress-90:before {
  content: "90%";
}
.progress-bar-container.progress-90 > .progress > .progress-bar {
  width: 90%;
}
.progress-bar-container.progress-91:before {
  content: "91%";
}
.progress-bar-container.progress-91 > .progress > .progress-bar {
  width: 91%;
}
.progress-bar-container.progress-92:before {
  content: "92%";
}
.progress-bar-container.progress-92 > .progress > .progress-bar {
  width: 92%;
}
.progress-bar-container.progress-93:before {
  content: "93%";
}
.progress-bar-container.progress-93 > .progress > .progress-bar {
  width: 93%;
}
.progress-bar-container.progress-94:before {
  content: "94%";
}
.progress-bar-container.progress-94 > .progress > .progress-bar {
  width: 94%;
}
.progress-bar-container.progress-95:before {
  content: "95%";
}
.progress-bar-container.progress-95 > .progress > .progress-bar {
  width: 95%;
}
.progress-bar-container.progress-96:before {
  content: "96%";
}
.progress-bar-container.progress-96 > .progress > .progress-bar {
  width: 96%;
}
.progress-bar-container.progress-97:before {
  content: "97%";
}
.progress-bar-container.progress-97 > .progress > .progress-bar {
  width: 97%;
}
.progress-bar-container.progress-98:before {
  content: "98%";
}
.progress-bar-container.progress-98 > .progress > .progress-bar {
  width: 98%;
}
.progress-bar-container.progress-99:before {
  content: "99%";
}
.progress-bar-container.progress-99 > .progress > .progress-bar {
  width: 99%;
}
.progress-bar-container.progress-100:before {
  content: "100%";
}
.progress-bar-container.progress-100 > .progress > .progress-bar {
  width: 100%;
}
.extendable-input > .dropdown > input {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 20px;
}
.extendable-input > .dropdown > ul {
  top: 0;
  left: 0;
  right: 0;
  box-shadow: none;
  height: 100%;
}
.extendable-input > .dropdown > ul > li {
  height: 100%;
}
.extendable-input > .dropdown > ul > li > div {
  width: 100%;
  outline: none;
  border: 2px solid #6D54FF;
  border-radius: 10px;
  resize: none;
  min-height: 100%;
  overflow: hidden;
  padding: 10px;
  font-size: 1rem;
  padding: 0 20px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  box-shadow: none;
  border: 2px solid #B0B39D;
  background-color: #fff;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
@media screen and (min-width: 375px) {
  .extendable-input > .dropdown > ul > li > div {
    font-size: calc(1rem + 5 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .extendable-input > .dropdown > ul > li > div {
    font-size: 1.25rem;
  }
}
@media screen and (min-width: 375px) {
  .extendable-input > .dropdown > ul > li > div {
    padding-top: calc(0.5rem + 5 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .extendable-input > .dropdown > ul > li > div {
    padding-top: 0.75rem;
  }
}
@media screen and (min-width: 375px) {
  .extendable-input > .dropdown > ul > li > div {
    padding-bottom: calc(0.5rem + 5 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .extendable-input > .dropdown > ul > li > div {
    padding-bottom: 0.75rem;
  }
}
.extendable-input > .dropdown > ul > li > div:focus {
  border-color: #6D54FF;
}
.btn-language-dropdown {
  display: inline-block;
}
.btn-language-dropdown > button {
  padding: 0 35px;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  width: 6.05rem;
  position: relative;
  box-sizing: content-box;
}
@media screen and (min-width: 480px) {
  .btn-language-dropdown > button {
    padding-top: calc(0.5rem + 5 * ((100vw - 480px) / 512));
  }
}
@media screen and (min-width: 992px) {
  .btn-language-dropdown > button {
    padding-top: 0.75rem;
  }
}
@media screen and (min-width: 480px) {
  .btn-language-dropdown > button {
    padding-bottom: calc(0.5rem + 5 * ((100vw - 480px) / 512));
  }
}
@media screen and (min-width: 992px) {
  .btn-language-dropdown > button {
    padding-bottom: 0.75rem;
  }
}
@media screen and (min-width: 480px) {
  .btn-language-dropdown > button {
    width: calc(6.05rem + 21 * ((100vw - 480px) / 512));
  }
}
@media screen and (min-width: 992px) {
  .btn-language-dropdown > button {
    width: 7.1rem;
  }
}
.btn-language-dropdown > button .icon {
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.btn-language-dropdown > button .icon:first-child {
  left: 33px;
}
.btn-language-dropdown > button .icon:last-child {
  right: 33px;
}
.btn-language-dropdown > button .icon-language {
  font-size: 1.25rem;
}
@media screen and (min-width: 375px) {
  .btn-language-dropdown > button .icon-language {
    font-size: calc(1.25rem + 5 * ((100vw - 375px) / 1545));
  }
}
@media screen and (min-width: 1920px) {
  .btn-language-dropdown > button .icon-language {
    font-size: 1.5rem;
  }
}
.btn-language-dropdown > button:hover,
.btn-language-dropdown > button:active,
.btn-language-dropdown > button:focus,
.btn-language-dropdown > button:active:focus {
  text-decoration: none;
}
.btn-language-dropdown > button:hover span:not([class]),
.btn-language-dropdown > button:active span:not([class]),
.btn-language-dropdown > button:focus span:not([class]),
.btn-language-dropdown > button:active:focus span:not([class]) {
  text-decoration: underline;
}
.btn-language-dropdown > ul.dropdown-menu > li:first-child > a {
  border-top: 2px solid #6D54FF;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
/*# sourceMappingURL=mediprepare-theme.css.map */