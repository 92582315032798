.form-group {
    .scale-prop(margin-bottom, 15px, 20px, @screen-mobile, @screen-desktop);
}

.control-label {
    .scale-prop(font-size, 18px, 22px, @screen-mobile, @screen-desktop);
    margin-bottom: 2px;
    color: @brand-default;
    white-space: nowrap;
}

.form-control {
    height: auto;
    .scale-prop(font-size, 20px, 25px, @screen-mobile, @screen-desktop);
    padding: 0 20px;
    .scale-prop(padding-top, 10px, 15px, @screen-mobile, @screen-desktop);
    .scale-prop(padding-bottom, 10px, 15px, @screen-mobile, @screen-desktop);
    box-shadow: none;
    border: 2px solid #B0B39D;
    border-radius: 10px;
    &:disabled,
    &::placeholder {
        color: @brand-secondary;
    }
    &.placeholder {
        color: @brand-secondary;
    }
    textarea& {
        resize: vertical;
        .scale-prop(min-height, 45px, 60px, @screen-mobile, @screen-desktop);
    }
}

.form-group:not([class*="has"]) {
    .form-control {
        &:hover:not(:disabled),
        &:focus {
            border-color: @brand-primary !important;
            box-shadow: none !important;
        }
    }
}

.form-group[class*="has"] {
    .input-group-addon {
        background-color: transparent;
    }
}

.input-group {
    width: 100%;
    .form-control {
        border-radius: 10px !important;
    }
}

.input-group-addon {
    background: transparent;
    border: none;
    width: auto;
}

.control-index {
    color: @brand-default;
    .scale-prop(font-size, 18px, 22px, @screen-mobile, @screen-desktop);
    padding-left: 0;
    .scale-prop(padding-right, 10px, 17px, @screen-mobile, @screen-desktop);
    .scale-prop(padding-top, 13px, 18px, @screen-mobile, @screen-desktop);
    font-weight: bold;
    vertical-align: top;
    width: 1%;
}

.control-tooltip {
    text-align: center;
    .scale-prop(font-size, 30px, 40px, @screen-mobile, @screen-desktop);
    .scale-prop(padding-bottom, 7px, 10px, @screen-mobile, @screen-desktop);
    line-height: 0;
    width: 1%;
    .btn-icon {
        line-height: 1;
    }
    input ~ & {
        vertical-align: middle;
    }
    textarea ~ & {
        vertical-align: bottom;
    }
}

.has-error {
    .box-shadow(@shadow) {}
    .form-control-validation(@brand-danger, @brand-danger, @brand-danger);
    .form-control {
        color: @brand-danger;
        &:focus {
            box-shadow: none;
        }
    }
}