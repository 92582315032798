.btn-group.switch {
    width: 100%;
    .btn {
        color: @brand-secondary;
        border-color: @brand-secondary;
        &:hover {
            color: @brand-primary;
            border-color: @brand-primary;
        }
    }
    .btn:not(:first-child) {
        margin-left: -2px;
    }
    .btn.active {
        background: @brand-primary;
        color: #fff;
        border-color: @brand-primary;
        &:active,
        &:focus,
        &:hover,
        &:active:focus,
        &:active:hover {
            background: @brand-primary;
            border-color: @brand-primary;
            color: #fff;
        }
        z-index: 3;
    }
}