.popover {
    border: 2px solid;
    background-color: #F1F1EE;
    box-shadow: none;
    .scale-prop(font-size, 18px, 22px, @screen-mobile, @screen-desktop);
    padding: 0;
    display: block;
    &-content {
        padding: 0;
    }
    &.top {
        margin-bottom: 0.75rem,
    }
    &.right {
        margin-left: 0.75rem,
    }
    &.bottom {
        margin-top: 0.75rem,
    }
    &.left {
        margin-left: -0.75rem,
    }
    .panel {
        &-body {
            border: none;
        }
    }
    @media screen and(max-width: @screen-sm-max) {
        & {
            width: 100%;
            max-width: 100%;
        }
    }
    @media screen and(min-width: @screen-md) {
        display: inline-block;
        width: auto;
        &.top {
            margin-bottom: 1rem,
        }
        &.right {
            margin-left: 1rem,
        }
        &.bottom {
            margin-top: 1rem,
        }
        &.left {
            margin-left: -1rem,
        }
    }
}

.popover {
    .popover-variant(@brand-secondary);
    &.open {
        .popover-variant(@brand-primary);
    }
}

.popover-variant (@color) {
    border-color: @color;
    &.top>.arrow {
        &:after {
            border-top-color: @color;
        }
    }
    &.right>.arrow {
        &:after {
            border-right-color: @color;
        }
    }
    &.bottom>.arrow {
        &:after {
            border-bottom-color: @color;
        }
    }
    &.left>.arrow {
        &:after {
            border-left-color: @color;
        }
    }
}
