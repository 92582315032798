@backgroundcolor: @brand-default;
@filledcolor: @brand-radialempty;

.icon-progress{
  display: block;
  position: relative;
  border-radius: 50%;
  .scale-prop(font-size, 13px, 16px, @screen-mobile, @screen-desktop);
  width: 1em;
  height: 1em;
  background-color: @backgroundcolor;
}
.icon-progress-filler{
  box-sizing: content-box;
  position: absolute;
  width: 1em;
  height: 1em;
  &:before,
  &:after{
    content: "";
    display:block;
    position: absolute;
    border: .5em solid @filledcolor;
    width: 0;
    height: 0;
    clip: rect(0,.5em,1.08em,0);
    border-radius: 50%;
    box-sizing: content-box;
  }
}

.icon-progress {
  .generate-radioprogressbar-values();
}

.generate-radioprogressbar-values(@i: 0, @n: 100) when (@i <= @n) {
  &.progress-@{i} {
    .radioprogressbar(@i);
  }
  .generate-radioprogressbar-values((@i + 10));
}

.radioprogressbar(@progress) when (@progress <= 50) and (@progress >= 0){
  & > .icon-progress-filler{
    clip: rect(0,1.08em,1.08em,.5em);
    &:before{
      -ms-transform: rotate((3.6deg * @progress)) scale(1.08); 
      transform: rotate((3.6deg * @progress)) scale(1.08);
    }
  }
}
.radioprogressbar(@progress) when (@progress <=100) and (@progress > 50){
  & > .icon-progress-filler{
    clip: rect(auto, auto, auto, auto);
    &:before{
      -ms-transform: rotate((3.6deg * @progress)) scale(1.08);
      transform: rotate((3.6deg * @progress)) scale(1.08);
    }
    &:after{
      -ms-transform: rotate(180deg) scale(1.08);
      transform: rotate(180deg) scale(1.08);
    }
  }
}