.icon {
    line-height: inherit;
}

.icon-down:before {
    content: "\e900";
}

.icon-left:before {
    content: "\e901";
}

.icon-right:before {
    content: "\e902";
}

.icon-language:before {
    content: "\e903";
}

.icon-untick:before {
    content: "\e904";
}

.icon-tick:before {
    content: "\e905";
}

.icon-info:before {
    content: "\e906";
}

.icon-info-hover:before {
    content: "\e916";
}

.icon-link:before {
    content: "\e907";
}

.icon-link-hover:before {
    content: "\e917";
}

.icon-progress-doing:before {
    content: "\e90e";
}

.icon-progress-todo:before {
    content: "\e910";
}
.icon-delete:before {
    content: "\e90d";
}