a {
    cursor: pointer;
    color: @brand-primary;
}

.page-header {
    .title {
        color: @brand-default;
        .scale-prop(font-size, 38px, 70px, @screen-mobile, @screen-desktop);
    }
    .subtitle {
        color: @brand-primary;
        .scale-prop(font-size, 22px, 25px, @screen-mobile, @screen-desktop);
    }
    .description {
        color: @brand-secondary;
        .scale-prop(font-size, 22px, 25px, @screen-mobile, @screen-desktop);
    }

    border: none;

    .text {
        &-questionnaire {
            &-title {
                margin: 0;
            }
            &-section {
                margin: 0.5rem 0 0 0;
            }
        }
    }
}

.text {
    &-default {
        color: @brand-default;
    }
    &-primary {
        color: @brand-primary;
    }
    &-secondary {
        color: @brand-secondary;
    }
    &-questionnaire {
        &-title {
            .scale-prop(font-size, 20px, 35px, @screen-mobile, @screen-desktop);
        }
        &-section {
            .scale-prop(font-size, 18px, 25px, @screen-mobile, @screen-desktop);
        }
    }
    &-question {
        .scale-prop(font-size, 22px, 35px, @screen-mobile, @screen-desktop);
        font-weight: bold;
        .scale-prop(margin-top, 15px, 80px, @screen-mobile, @screen-desktop);
        .scale-prop(margin-bottom, 8px, 25px, @screen-mobile, @screen-desktop);
        &-sub {
            .scale-prop(font-size, 20px, 30px, @screen-mobile, @screen-desktop);
            font-weight: bold;
            .scale-prop(margin-top, 15px, 80px, @screen-mobile, @screen-desktop);
            .scale-prop(margin-bottom, 8px, 25px, @screen-mobile, @screen-desktop);
        }
        &-definition {
            .scale-prop(font-size, 20px, 30px, @screen-mobile, @screen-desktop);
            .scale-prop(margin-top, 15px, 40px, @screen-mobile, @screen-desktop);
            .scale-prop(margin-bottom, 8px, 25px, @screen-mobile, @screen-desktop);       
        }
        &-answer {
            .scale-prop(font-size, 20px, 25px, @screen-mobile, @screen-desktop);
        }
    }
    &-label {
        .scale-prop(font-size, 18px, 22px, @screen-mobile, @screen-desktop);
        font-weight: bold;
    }
    &-tooltip {
        .scale-prop(font-size, 18px, 22px, @screen-mobile, @screen-desktop);
        .scale-prop(margin-bottom, 21px, 25px, @screen-mobile, @screen-desktop);
    }
    &-dashboard {
        &-welcome {
            .scale-prop(font-size, 38px, 70px, @screen-mobile, @screen-desktop);
            font-weight: bold;
        }
        &-introduction {
            .scale-prop(font-size, 22px, 25px, @screen-mobile, @screen-desktop);
        }
        &-subtitle {
            .scale-prop(font-size, 22px, 25px, @screen-mobile, @screen-desktop);
        }
        &-status-label {
            .scale-prop(font-size, 18px, 20px, @screen-mobile, @screen-desktop);
        }
        &-questionnaire-title {
            .scale-prop(font-size, 20px, 30px, @screen-mobile, @screen-desktop);
        }
    }
}

