@import '../bootstrap/variables';
@import '../bootstrap/mixins/border-radius';
@import '../bootstrap/mixins/forms';
@import 'variables';
@import '../font-faces/muli';
@import 'scale';
html,
body {
    font-size: @base-font-size;
    font-family: @font-family-sans-serif;
}

@import 'typography';
@import 'grid';
@import 'buttons';
@import 'icons';
@import 'forms';
@import 'checkboxes';
@import 'switches';
@import 'panels';
@import 'dropdowns';
@import 'popovers';
@import 'navbar';
@import 'radial-progressbar';
@import 'progressbar';
@import 'extendable-input';

.btn-language-dropdown {
    display: inline-block;
    & > button {
        padding: 0 35px;
        .scale-prop(padding-top, 10px,  15px, @screen-xs, @screen-md);
        .scale-prop(padding-bottom, 10px,  15px, @screen-xs, @screen-md);
        .scale-prop(width, 121px, 142px, @screen-xs, @screen-md);
        position: relative;
        box-sizing: content-box;
        .icon{
            position: absolute;
            top:50%;
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
        }
        .icon:first-child{
            left:33px;
            
            
        }
        .icon:last-child{
            right:33px;
        }
        .icon-language {
            .scale-prop(font-size, 25px, 30px,   @screen-mobile, @screen-desktop);
        }
        &:hover,
        &:active,
        &:focus,
        &:active:focus {
            text-decoration: none;
            span:not([class]){
                text-decoration: underline;
            }
        }
    }
    & > ul.dropdown-menu {
        & > li:first-child{
            & > a {
                border-top: 2px solid @brand-primary;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
            }
        }
    }
    
}