.panel {
    border: none;
    box-shadow: none;
    &-heading {
        border: 1px solid;
        .border-top-radius(6px);
        border-color: @brand-primary;
        background-color: @brand-primary;
        color: #FFF;
        .scale-prop(font-size, 18px, 25px, @screen-mobile, @screen-desktop);
        .scale-prop(padding-top, 15px, 25px, @screen-mobile, @screen-desktop);
        .scale-prop(padding-bottom, 15px, 25px, @screen-mobile, @screen-desktop);
        .scale-prop(padding-left, 12px, 35px, @screen-mobile, @screen-desktop);
        .scale-prop(padding-right, 12px, 35px, @screen-mobile, @screen-desktop);
    }
    &-body,
    &-footer,
    .list-group {
        overflow: hidden;
        border: 1px solid;
    }
    *+.list-group {
        border-top-width: 0;
    }
}

.panel-color-variant (@color) {
    .panel {
        &-heading {
            background-color: @color;
            color: #FFF;
        }
        &-heading,
        &-searchbox,
        &-footer,
        &-body {
            border-color: @color;
        }
    }
    .list-group {
        border-color: @color;
    }
}

.panel-primary {
    .panel-color-variant(@brand-primary);
}

.panel-secondary {
    .panel-color-variant(@brand-secondary);
}

.panel-tooltip {
    background-color: #F1F1EE;
    margin: 0;
    .panel-body {
        .scale-prop(padding, 20px, 30px, @screen-mobile, @screen-desktop);
    }
}

