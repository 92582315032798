.progress-bar-container {
    position: relative;
    .scale-prop(margin-top, 12px, 17px, @screen-md-min, @screen-desktop);
    &:before {
        display: block;
        float: left;
        font-weight: bold;
        .scale-prop(font-size, 18px, 22px, @screen-xs, @screen-md);
        color: @brand-primary;
        .scale-prop(margin-right, 10px, 25px, @screen-xs, @screen-md);
    }
    &.progress-0 {
        .beltprogressbar(0);
        &:before {
            color: @brand-secondary;
        }
    }
    .generate-progress-values();
    &>.progress {
        display: block;
        margin-bottom: 0;
        margin-right: 0;
        border-radius: 0;
        background-color: @brand-secondary;
        .scale-prop(font-size, 18px, 22px, @screen-xs, @screen-md);
        position: relative;
        &>.progress-bar {
            background-color: @brand-primary;
        }
    }
}
.progress-bar-container {
    .generate-progress-values();
}

.beltprogressbar(@progress) {
    &:before {
        content: "@{progress}%";
    }
    &>.progress {
        &>.progress-bar {
            width: (@progress * 1%);
        }
    }
}

.generate-progress-values(@i: 1, @n: 100) when (@i =< @n) {
    &.progress-@{i} {
        .beltprogressbar(@i);
    }
    .generate-progress-values((@i + 1));
}