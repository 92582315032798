.button-variant(@color) {
    background: @color;
    color: #fff;
    border-color: @color;
    &:active,
    &:focus,
    &:hover,
    &:active:focus,
    &:active:hover {
        background: #fff;
        border-color: @color;
        color: @color;
    }
    &.btn-flat,
    &.btn-link {
        background: transparent;
        border-color: transparent;
        color: @color;
        &:active,
        &:focus,
        &:active:focus {
            border-color: transparent;
        }
    }
}

.btn-base () {
    border-radius: @border-radius-default;
    .scale-prop(font-size, 18px, 22px, @screen-mobile, @screen-desktop);
    .scale-prop(padding-left, 30px, 35px, @screen-mobile, @screen-desktop);
    .scale-prop(padding-right, 30px, 35px, @screen-mobile, @screen-desktop);
    .scale-prop(padding-top, 11px, 16px, @screen-mobile, @screen-desktop);
    .scale-prop(padding-bottom, 11px, 16px, @screen-mobile, @screen-desktop);
    border: 2px solid;
    align-items: center;
    white-space: nowrap;
    text-align: center;
    border-color: transparent;

}

.btn {
    .btn-base();
    &:active,
    &.active,
    &:focus,
    &:active:focus
    &.active:focus {
        box-shadow: none !important;
        outline: none !important;
    }
    .icon {
        font-size:0.8rem;
        &:first-child {
            margin-right: 0;
        }
        &:last-child {
            margin-left: 0;
        }
        &-right,
        &-left{
            position: relative;
            top: -0.1em;
        }
        &-right{
            left: 0.3em;
        }
        &-left{
            left: -0.3em;
        }
        &-down{
            position: relative;
            top: 0.1em;
        }
    }
}

.btn-default {
    .button-variant(@brand-default);
}

.btn-primary {
    .button-variant(@brand-primary);
}

.btn-secondary {
    .button-variant(@brand-secondary);
}

