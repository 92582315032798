.dropdown {
    position: relative;
    &:hover {
        &>.icon {
            color: @brand-primary;
        }
        &>input {
            border-color: @brand-primary;
        }
    }
    &>.icon {
        position: absolute;
        .scale-prop(right, 20px, 25px, @screen-mobile, @screen-desktop);
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        color: @brand-secondary;
        transition: color ease-in-out .15s, box-shadow ease-in-out .15s;
        cursor: pointer;
    }
    &.open {
        &>input {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border-color: @brand-primary;
        }
        &>.icon {
            color: @brand-primary;
        }
    }
    &>ul {
        width: 100%;
        padding-top: 0;
        margin-top: 0;
        margin-bottom: 0;
        padding-bottom: 0;
        border: none;
        border-radius: 10px;
        &>li {
            &>a {
                border-bottom: 2px solid @brand-primary;
                border-right: 2px solid @brand-primary;
                border-left: 2px solid @brand-primary;
                &:hover {
                    background-color: transparent;
                }
            }
            &:first-child {
                &>a {
                    border-top: none;
                }
            }
            &:last-child {
                &>a {
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                }
            }
            &>a {
                .scale-prop(font-size, 20px, 25px, @screen-mobile, @screen-desktop);
                padding: 0 20px;
                .scale-prop(padding-top, 10px, 15px, @screen-mobile, @screen-desktop);
                .scale-prop(padding-bottom, 10px, 15px, @screen-mobile, @screen-desktop);
                &:hover {
                    color: @brand-primary;
                }
            }
        }
    }
    &>input {
        .scale-prop(padding-right, 35px, 40px, @screen-mobile, @screen-desktop);
        &:focus,
        &:hover {
            &+.icon {
                color: @brand-primary;
            }
        }
    }
}

@media screen and (max-width: @screen-md) {
    .navbar-nav .open .dropdown-menu {
        position: absolute;
        width: 100%;
        background-color: #fff;
    }
    .navbar-default .navbar-nav .open .dropdown-menu>li>a {
        color: @brand-default;
        &:hover,
        &:focus {
            color: @brand-primary;
        }
    }
    .navbar-inverse .navbar-nav .open .dropdown-menu>li>a {
        color: @brand-default;
        &:hover,
        &:focus {
            color: @brand-primary;
        }
    }
}