.extendable-input {
    &>.dropdown {
        &>input {
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            padding-right: 20px;
        }
        &>ul {
            top: 0;
            left: 0;
            right: 0;
            box-shadow: none;
            height: 100%;
            &>li {
                height: 100%;
                &>div {
                    width: 100%;
                    outline: none;
                    border: 2px solid @brand-primary;
                    border-radius: 10px;
                    resize: none;
                    min-height: 100%;
                    overflow: hidden;
                    padding: 10px;
                    .scale-prop(font-size, 20px, 25px, @screen-mobile, @screen-desktop);
                    padding: 0 20px;
                    .scale-prop(padding-top, 10px, 15px, @screen-mobile, @screen-desktop);
                    .scale-prop(padding-bottom, 10px, 15px, @screen-mobile, @screen-desktop);
                    box-shadow: none;
                    border: 2px solid #B0B39D;
                    background-color: #fff;
                    overflow-wrap: break-word;
                    word-wrap: break-word;
                    &:focus {
                        border-color: @brand-primary;
                    }
                }
            }
        }
    }
}