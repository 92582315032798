
    @font-face {
        font-family: 'Muli';
        font-style: normal;
        font-weight: 400;
        src: url('@{fonts-dir}/muli-v11-latin_latin-ext-regular.eot');
        /* IE9 Compat Modes */
        src: local('Muli Regular'),
        local('Muli-Regular'),
        url('@{fonts-dir}//muli-v11-latin_latin-ext-regular.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */
        url('@{fonts-dir}//muli-v11-latin_latin-ext-regular.woff2') format('woff2'),
        /* Super Modern Browsers */
        url('@{fonts-dir}//muli-v11-latin_latin-ext-regular.woff') format('woff'),
        /* Modern Browsers */
        url('@{fonts-dir}//muli-v11-latin_latin-ext-regular.ttf') format('truetype'),
        /* Safari, Android, iOS */
        url('@{fonts-dir}//muli-v11-latin_latin-ext-regular.svg#Muli') format('svg');
        /* Legacy iOS */
    }