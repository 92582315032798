.navbar-fixed-top,
.navbar-fixed-bottom {
    z-index: 1070;
}

.navbar-default,
.navbar-inverse {
    box-shadow: 0 0 30px -10px black;
    .scale-prop(padding, 16px, 16px, @screen-mobile, @screen-desktop);
}

.navbar-default {
    background-color: #fff;
    .btn-link {
        &.open,
        &:active,
        &:hover,
        &:focus,
        &:active:hover,
        &active:focus {
            color: @brand-primary;
        }
    }
}

.navbar-inverse {
    background-color: @brand-primary;
    border-color: transparent;
    color: white;
    .btn {
        color: #fff;
        &.open,
        &:active,
        &:hover,
        &:focus,
        &:active:hover,
        &active:focus {
            color: #fff;
            background-color: @brand-primary;
            border-color: transparent;
        }
    }
}