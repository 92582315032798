.checkbox,
.radio {
    .scale-prop(margin-bottom, 30px, 30px, @screen-mobile, @screen-desktop);
    input[type="radio"],
    input[type="checkbox"] {
        opacity: 0;
    }

    label {
        margin: 0;
        padding: 0;
    }
    input+.label-text {
        .scale-prop(font-size, 20px, 25px, @screen-mobile, @screen-desktop);
        .scale-prop(height, 24px, 40px, @screen-mobile, @screen-desktop);
        &::before {
            color: @brand-primary;
            font-family: @icon-font;
            left: 0;
            .scale-prop(font-size, 24px, 40px, @screen-mobile, @screen-desktop);
            .scale-prop(margin-right, 15px, 22px, @screen-mobile, @screen-desktop);
        }
    }
    input:checked+.label-text {
        &::before {
            font-family: @icon-font;
        }
    }
    input:disabled+.label-text {
        color: @brand-secondary;
        &::before {
            color: @brand-secondary;
        }
    }

    .form-group {
        display: inline-block;
    }
}

.checkbox {
    input+.label-text {
        &::before {
            content: '\e904';
        }
    }
    &:hover input:not(:checked):not(:disabled)+.label-text {
        &::before {
            content: '\e918';
        }
    }
    input:checked+.label-text {
        &::before {
            content: '\e905';
        }
    }
    input:disabled+.label-text {
        color: @brand-secondary;
        &::before {
            color: @brand-secondary;
        }
    }
}

.radio {
    display: table;
    input+.label-text {    
        &::before {
            content: '\e90c';
        }
    }
    &:hover input:not(:checked):not(:disabled)+.label-text {
        &::before {
            content: '\e90a';
        }
    }
    input:checked+.label-text {
        &::before {
            content: '\e90b';
        }
    }
    label {
        display: table-cell;    
        vertical-align: middle;        
    }
    .form-group {
        display: table-cell;
        margin: 0;
    }
    input:disabled+.label-text {
        color: @brand-secondary;
        &::before {
            color: @brand-secondary;
        }
    }
}